@import url(https://fonts.googleapis.com/css2?family=Recursive&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {

  margin-bottom: 2em;
  color:red;
  margin-top: 2em;
  margin-left: 2em;
  
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 2em;
}

.App-link {
  color: #61dafb;
}
.autocomplete-box
{
  color: red;
  width: 100%;
  height: 10px;
  border-radius: 20px 20px 0 0;
  
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
    font-family: 'Recursive', sans-serif;
}

.autocomplete {
    font-family: 'Recursive', sans-serif;
    margin-bottom: 10pt;
    color:blue;
    width: 100%;
    border-radius: 4px;

}
.react-autosuggest__suggestions-container{
    color:whitesmoke;
    margin:0;
    width: 700px;
    /* border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px */
    

}

.react-autosuggest__suggestions-container :hover {
    background-color:#05355a;
}

.react-autosuggest__suggestions-container :last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.react-autosuggest__input :focus {

    outline: none !important;
    border:1px solid red;
    box-shadow: 0 0 10px #719ECE;
}
.highlight
{
    color:white;
    font-weight: bold;
    border: none;
}
.react-autosuggest__input {
    width: 700px;
    height: 60px;
    border:10px solid red;
    border-radius: 20px 20px 0 0;
    padding-left: 30px;
    background-color:#01579b;;
    border: none;
    color: whitesmoke;
    font-size: x-large;
    font-family: 'Recursive', sans-serif;
    

}
textarea:focus, input:focus{
    outline:none;
    background-color: #05355a;
}

.name {
    background-color: #01579b;
    /* border-radius: 20px 20px 0 0; */
    width:700px;
    font-size: 25px;
    color: black;
    margin-bottom: 20px;
}
/* .react-autosuggest__suggestion  :hover {
    background-color: lightgrey;
      /* color:black; 
} 
*/
ul
{
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    /* border-radius: 20px; */
    border-color: black;
    
}
/* ul :hover {
    background-color: whitesmoke;
    color: black;
} */

.react-autosuggest__suggestion {
    background-color:  #01579b;
    color: whitesmoke;
    height:60px;
    border:1px solid black;
    /* border-radius: 20px; */
    padding-left:2em;
    padding-right:1em;
    /* border-bottom-right-radius: 20px; */
    /* border-top-left-radius: 20px; */
    padding-top: 1em;
    padding-bottom: 70px;
    /* border-radius: 20px; */
    /* border-width:2pt; */
    margin: 0pt;
    /*padding-top: 10px; */
    width: 700px;
    
}

/* .react-autosuggest__suggestion :hover {
    color:black
} */
li{
    list-style:none;
}
/* li :hover{
    background-color:#05355a;
} */
/* li :last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
} */
  .sugg-option {
    display: flex;
    color: blue;
    /* padding-bottom: 20px; */
    height: 70px;
    padding:0;
  }
  /* .sugg-option :hover {
      background-color:#05355a;
  } */

  .sugg-option .name {
      color: whitesmoke;
      display:flex;
      /* padding-bottom:1em; */
      margin-bottom:20px; 
      padding-bottom: 30px;  
      line-break:strict;
      line-height: normal;   
      /* border-radius: 20px 20px 0 0;  */
      padding-left: 0;
  }

.main{
    width: 700px;
    border-radius: 20px;
    background-color: #01579b;
    color: whitesmoke;
    margin-bottom: 15pt;
    padding-left:15pt;
    padding-right:15pt;
}
    
.top{
    height: 60px;
    background-color: #424242;
    color: whitesmoke;
    padding: 10px;
    border-radius: 20px 20px 0 0;
    font-family: 'Recursive', sans-serif;
    display: flex;
    justify-content: space-between;
}
.h1{
    color: whitesmoke;
    margin-left: 2em;
}
.header{
    background-color: #424242;
    color: whitesmoke;
    margin: 10px 0px 0px 10px;
    font-size: 25px;
    border-radius: 20px 20px 0 0;
    font-family: 'Recursive', sans-serif;
}

.day{
    padding: 10px;
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.temp{
    padding: 10px;
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 18px;
}

.flex{
    display: flex;
    justify-content: space-between;
}

.sunrise-sunset{
    padding: 10px;
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 16px;
}

.wind{
    padding: 10px;
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 16px;
}
.rain{
    padding: 10px;
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 16px;
}
.last-update {
    padding: 10px;
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 10px;
    
}
.description{
    padding: 10px;
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 24px;
    font-weight: 600;
}
.button{
    width: 35px;
    height: 35px;
    
}
