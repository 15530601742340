.App {

  margin-bottom: 2em;
  color:red;
  margin-top: 2em;
  margin-left: 2em;
  
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 2em;
}

.App-link {
  color: #61dafb;
}
.autocomplete-box
{
  color: red;
  width: 100%;
  height: 10px;
  border-radius: 20px 20px 0 0;
  
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
